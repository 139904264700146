<template>
  <Bread BreadTitle="止觀初探"></Bread>

  <div class="row">
    <div class="col-md-3">
      <div class="input-group">
        <span class="input-group-text">進度</span>
        <select v-model="basedata.selected" class="form-control" id="sel1">
          <option v-for="(n, index) in basedata.ShamathaData" :key="index">
            {{ n.sid }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-9"></div>
  </div>
  <iframe
    width="350"
    height="300"
    :src="selectedUrl"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
import { onMounted, reactive, computed, watch, ref } from "vue";
import { GetShamathaList } from "../../api.js";
import Bread from "../components/Bread.vue";
export default {
  components: {
    Bread,
  },
  setup() {
    let errorStatus = ref("");
    const selectedUrl = computed(() => {
      let vFind = basedata.ShamathaData.find((x) => x.sid == basedata.selected);

      if (vFind == undefined) return "";

      return vFind.source;
    });
    const basedata = reactive({
      selected: null,
      ShamathaData: [],
    });

    function GetData() {
      GetShamathaList()
        .then(function (response) {
          basedata.ShamathaData = response.data;
          basedata.ShamathaData.sort((a, b) => (b.SID > a.SID ? 1 : -1));
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    onMounted(() => {
      GetData();
      //預設值
      basedata.selected = basedata.ShamathaData.length;

      var vRecordLocalStorageshamathaBook = localStorage.getItem(
        "shamathaSelected"
      );

      if (vRecordLocalStorageshamathaBook == null)
        vRecordLocalStorageshamathaBook = basedata.ShamathaData.length;

      basedata.selected = vRecordLocalStorageshamathaBook;
    });

    watch(
      () => basedata.selected,
      (vNew, vOld) => {
        console.log("basedata.selected-vNew", vNew);
        console.log("basedata.selected-vOld", vOld);

        //存 local storage
        localStorage.setItem("shamathaSelected", vNew);
      }
    );

    return { basedata, selectedUrl, GetData, errorStatus };
  },
};
</script>

<style>
</style>